import "@fortawesome/fontawesome-free/css/all.css"

import css from "@emotion/css"
import { Container, Section, Title } from "bloomer"
import { graphql, Link } from "gatsby"
import React from "react"

import { YELLOW } from "../consts/colors.constants"
import LayoutContainer from "../layouts"

const LinkList = ({ pages, title, prefix }) => (
  <>
    <Title
      css={css`
        color: ${YELLOW};
      `}
    >
      {title}
    </Title>
    <ul>
      {pages.map(p => (
        <Link key={p.id} to={prefix ? prefix + p.slug : p.path}>
          <li>{p.title || p.name}</li>
        </Link>
      ))}
    </ul>
  </>
)

const SitemapPage = props => {
  const pages = props.data.pages.edges.map(edge => edge.node)
  const models = props.data.models.edges.map(edge => edge.node)
  const locations = props.data.locations.edges.map(edge => edge.node)
  const categories = props.data.categories.edges.map(edge => edge.node)
  return (
    <LayoutContainer themeName={`dark`} theme="dark">
      <Section className="sitemap-page">
        <Container>
          <LinkList pages={pages} title={`Pages`} />
          <LinkList pages={models} title={`Models`} />
          <LinkList pages={categories} prefix={`/`} title={`Categories`} />
          <LinkList pages={locations} title={`Locations`} />
        </Container>
      </Section>
    </LayoutContainer>
  )
}

export default SitemapPage

export const query = graphql`
  query querySitemapPage {
    models: allWordpressWpModel {
      edges {
        node {
          id
          wordpress_id
          title
          slug
          path
        }
      }
    }
    pages: allWordpressPage {
      edges {
        node {
          id
          wordpress_id
          title
          slug
          path
        }
      }
    }
    categories: allWordpressCategory(filter: { taxonomy: { slug: { eq: "category" } } }) {
      edges {
        node {
          id
          slug
          name
          wordpress_id
          path
        }
      }
    }
    locations: allWordpressWpCity {
      edges {
        node {
          id
          slug
          name
          path
          wordpress_id
        }
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
